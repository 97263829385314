<template>
    <div class="Awarp">
        <dashCard class="dieselgeneratorBox7" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">累计运行时间</template>
            <template slot="aside">
                <div class="type-group">
                    <span class="pick-type" v-for="(item,idx) in timetType" :key="idx" @click="changeType(item.code)">
                        <font :class="{'isactive-type': item.code == searchObj.TYPE}">{{ item.name }}</font>
                    </span>
                </div>
                <a-date-picker v-if="searchObj.TYPE=='按天'" :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"  v-model="searchObj.VALUE" @change="onChange"/>
                <a-week-picker v-if="searchObj.TYPE=='按周'" :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"  v-model="searchObj.VALUE" @change="onChange" />
                <a-month-picker v-if="searchObj.TYPE=='按月'" :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)" v-model="searchObj.VALUE" @change="onChange" />
                <a-date-picker v-if="searchObj.TYPE=='按年'" :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"  v-model="searchObj.VALUE" @change="onChange"
                mode="year"
                :open="panelOpen"
                @openChange="openChange"
                @panelChange="panelChange"
                format="YYYY"
                />
            </template>
            <div class="card-content1">
                <a-select v-model="searchObj['ITEM']" style="width: 445px" @change="handleChange" :getPopupContainer="triggerNode => triggerNode.parentNode" mode="multiple">
                    <a-select-option v-for="(item,key) in itemList" :key="key" :value="item.code">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
                <div class="content1-line" v-if="searchObj.TYPE=='按天'">
                    <div>
                        <span>1#发电机</span>
                        <span>7,233<font>h</font></span>
                    </div>
                    <div>
                        <span>2#发电机</span>
                        <span>2,766<font>h</font></span>
                    </div>
                    <div>
                        <span>3#发电机</span>
                        <span>1,233<font>h</font></span>
                    </div>
                    <div>
                        <span>4#发电机</span>
                        <span>716<font>h</font></span>
                    </div>

                </div>
            </div>
            <div class="card-content2" >
                <chartBoard ref="chart1" :option="chartOption1"/>
            </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <dieselgeneratorBox7  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    export default {
        name: 'dieselgeneratorBox7',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
            chartBoard: () => import('@/component/chartBoard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                } 
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                        var dom = this.$refs['chart1']
                        if(!!dom) {
                            dom.handleWindowResize()
                        }
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中

                timetType: [
                {
                    code: '按天',
                    name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
                },
                {
                    code: '按周',
                    name:'按周'
                },
                {
                    code: '按月',
                    name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
                },
                {
                    code: '按年',
                    name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
                }
            ],
            itemList: [
                {
                    code: '1#发电机',
                    name:'1#发电机'
                },
                {
                    code: '2#发电机',
                    name:'2#发电机'
                },
                {
                    code: '3#发电机',
                    name:'3#发电机'
                },
                {
                    code: '4#发电机',
                    name:'4#发电机'
                },
                {
                    code: '5#发电机',
                    name:'5#发电机'
                },
                {
                    code: '6#发电机',
                    name:'6#发电机'
                },

            ],
            panelOpen: false,
            searchObj: {
                TYPE: '按天',//按天,按周,按月,按年
                VALUE: this.$moment(),//日期
                ITEM: ['1#发电机','2#发电机','3#发电机','4#发电机'],//选中的项目
            },
            chartOption1: {},
            detailInfo: {
                chart1: {}
            },
            colorList: ['#3366FF', '#74CABB', '#469E3F','#EDE56F'],
            }
        },
        computed: {},
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },


        // 改变类型
        changeType(type) {
            this.searchObj.TYPE = type
            this.getData()
        },
	    openChange(status) {
            this.panelOpen = status
	    },
        // 年份弹窗关闭
	    panelChange(value){
	      this.searchObj.VALUE = value;
	      this.panelOpen = false;
          this.getData()
	    },
        onChange(value) {
            console.log("onChange",value,this.searchObj)
            this.getData()
            this.$forceUpdate()
        },
        // 切换项目
        handleChange(value) {
            this.getData()
        },
        //每月报警预警数
        getData() {
            this.isLoading = true
            this.detailInfo = {}
            var chart1 = {
                categoryData: ["Jan","Feb","Mar","Apr","May"],
                seriesData: [
                    {
                        name: '1#发电机',
                        value: [1130,2132,1247,2138,1800]
                    },
                    {
                        name: '2#发电机',
                        value: [1200,1200,1700,1800,2132]
                    },
                    {
                        name: '3#发电机',
                        value: [2100,2200,1700,1800,2032]
                    },
                    {
                        name: '4#发电机',
                        value: [1300,1200,1500,1200,1132]
                    },
                ]
            }
            this.detailInfo.chart1 = chart1
            this.initEchart()
            this.isLoading = false
        },
		initEchart() {
            var {categoryData,seriesData} = this.detailInfo.chart1
            var series = []
            seriesData.forEach(item=>{
                var obj = {
                    type: 'bar',
                    name: item.name,
                    data: item.value,
                    smooth: false,
                    showSymbol: false,
                    // barGap:0,
                    barWidth: 10,
                }
                series.push(obj)
            })
            var option = {
                animation:false,
                layoutAnimation:false,
                grid: {
                    left: '0%',
                    right: '1%',
                    bottom: '7%',
                    top: '15%',
                    containLabel: true
                },
                legend: {
                    right: 0,
                    top: '0%',
                    icon: "rect", //图例形状
                    itemWidth: 8,
                    itemHeight: 8,
                    textStyle: {
                        fontFamily: 'ABBvoice_WCNSG_Rg',
                        color: '#1d2129'
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                    }
                },
                dataZoom: [
                    {
                    type: 'inside',
                    start: 0,
                    end: 100,
                    height: 20,
                    bottom: 0,
                    },
                    {
                    start: 0,
                    end: 100,
                    height: 20,
                    bottom: 10,
                    }
                ],
                xAxis: {
                    type: 'category',
                    name: "",
                    data: categoryData,
                    boundaryGap: true,
                    nameTextStyle: {
                        fontFamily: 'ABBvoice_WCNSG_Rg',
                        color: '#9f9f9f',
                        fontSize: 14
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#1f1f1f'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        fontFamily: 'ABBvoice_WCNSG_Rg',
                        color: '#696969',
                        fontSize: 14
                    },
                },
                yAxis: {
                    type: 'value',
                    name: "(h)",
                    axisTick: {
                        show: false,
                    },
                    nameTextStyle: {
                        fontFamily: 'ABBvoice_WCNSG_Rg',
                        color: '#9f9f9f',
                        fontSize: 14
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#1f1f1f'
                        }
                    },
                    axisLabel: {
                        fontFamily: 'ABBvoice_WCNSG_Rg',
                        color: '#696969',
                        fontSize: 14
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            // type: "dashed",
                            color: '#ebebee'
                        }
                    },
                    // 控制数据参数最大值和最小值
                    // interval: 50,
                    // max: 100

                },
                // 控住柱状图样式
                series:series,
                color: this.colorList
            };

            this.updateChart('chart1', 'chartOption1', option)
        },
        updateChart: function (refName, optionName, option = {}) {
            /* 渲染echart图 */
            if (!optionName) return
            this[optionName] = option
            setTimeout(() => {
                this.$refs[refName].updateChartView()
            }, 500)
        },

        },
        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>

<style lang="less" scoped>
.dieselgeneratorBox7 {
    .card-content1 {
        .content1-line {
            margin-top: 20px;
            display: flex;
            >div {
                // height: 68px;
                // width: 185px;
                display: flex;
                flex-direction: column;
                width: 118px;
                height: 84px;
                background: #fafafa;
                border-radius: 4px;
                margin-right: 16px;
                box-sizing: border-box;
                padding: 16px;
                >span {
                    &:nth-child(1) {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(0,0,0,0.65);
                        line-height: 22px;
                    }
                    &:nth-child(2) {
                        font-size: 32px;
                        font-weight: 500;
                        color: #1d2129;
                        line-height: 40px;
                        font {
                            margin-left: 6px;
                            font-size: 12px;
                            font-weight: 400;
                            color: rgba(0,0,0,0.45);
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
    .card-content2 {
        flex: 1;
        height: 100%;
        div{
            width: 100%;
            height: 100%;
        }
    }
}
</style>
